import { AppProvider, i18Init } from "@sundae/gov-toolkit";
import ReactDOM from "react-dom/client";
import { useI18N } from "@sundae/react-hooks";
import { EEX_PATHS } from "./types";
import resourcesToBackend from "i18next-resources-to-backend";

import "./tailwind.scss";

(async () => {
  i18Init(
    (language, namespace, callback) => {
      import(`./translations/${language}/${namespace}.json`).then(({ default: resources }) =>
        callback(null, resources)
      );
    },
    (options) => {
      options.ns = [...options.ns, "sundaeswap"];
      options.debug = false;
      return options;
    }
  );
})();

const App = () => {
  const { i18n, t } = useI18N("sundaeswap");
  i18n.use(
    resourcesToBackend((language, namespace, callback) => {
      const path = `./translations/${language}/${namespace}.json`;
      import(path).then(({ default: resources }) => callback(null, resources));
    })
  );

  return (
    <AppProvider
      componentProps={{
        header: {
          navigationItems: [
            {
              to: "/",
              title: t("navigation.home"),
              getActiveProps() {
                return {
                  className: "active-nav-item",
                };
              },
            },
            {
              to: "/create",
              title: t("navigation.create"),
              getActiveProps() {
                return {
                  className: "active-nav-item",
                };
              },
            },
            {
              dropdown: {
                menuTitle: t("navigation.more"),
                menuContent: [
                  {
                    to: EEX_PATHS.HOME,
                    title: t("navigation.sundaeswap"),
                    description: t("navigation.sundaeswap"),
                    external: true,
                  },
                  {
                    to: EEX_PATHS.TWITTER,
                    title: t("navigation.twitter"),
                    description: t("navigation.twitter_desc"),
                    external: true,
                  },
                  {
                    to: EEX_PATHS.YOUTUBE,
                    title: t("navigation.youtube"),
                    description: t("navigation.youtube_desc"),
                    external: true,
                  },
                  {
                    to: EEX_PATHS.FAQ,
                    title: t("navigation.faq"),
                    description: t("navigation.faq_desc"),
                    external: true,
                  },
                ],
              },
            },
          ],
        },
      }}
      brand={{
        images: {
          audit_pending: "/static/images/audit_pending.png",
          casting_a_vote: "/static/images/casting_a_vote.png",
          fatal_error: "/static/images/fatal_error.png",
          not_enough_tokens: "/static/images/not_enough_tokens.png",
          proposal_created: "/static/images/proposal_created.png",
        },
      }}
    />
  );
};

const target = document.querySelector("#app");
const root = target && ReactDOM.createRoot(target);
if (root) {
  root.render(<App />);
}
